<template>
  <div class="">
    <SubHeader :data="data" />
    <div class="container">
      <div class="session-unlock">
        <h1>{{ $t(`Blogs['blogList']`) }}</h1>
        <el-row :gutter="24">
          <el-col v-for="(item, index) in blogList" :key="index" :xs="24" :sm="12" :md="12" :lg="8" style="padding-bottom: 25px;">
            <div class="card-list">
              <el-card :body-style="{ padding: '0px' }">
                <router-link :to="routes[index]"><img :src="item.imgCard" class="image"></router-link>
                <div style="padding: 10px 0 10px;">
                  <h1>{{ $t(`Blogs['${item.title}']`) }}</h1>
                  <p class="trancats">{{ $t(`Blogs['${item.description}']`) }}</p>
                  <div class="bottom clearfix">
                    <time class="time">{{ blogDate }}</time>
                    <!-- <el-button type="text" class="button"><el-icon class="el-icon-share"/> {{ $t(`Blogs['share']`) }}</el-button> -->
                  </div>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeader from '../components/SubHeader.vue'
import moment from 'moment'

export default {
  components: {
    SubHeader
  },
  data() {
    return {
      routes: {
        0: '/blogs/InstantMessagine',
        1: '/blogs/MosApp',
        2: '/blogs/CallingFriendsAndFamilyAround'
      },
      data: {
        label_name: 'exploreLearnInspire',
        content_desc: 'blog_content',
        img: require('@/assets/image/sub-header/explore.png')
      },
      blogList: [
        {
          title: 'instantMessagine',
          description: 'instantMessageInstruction',
          imgCard: require('@/assets/image/cards/Blog1.png')
        },
        {
          title: 'mosAppSafeguarding',
          description: 'mosAppSafeguardingDesc',
          imgCard: require('@/assets/image/cards/Blog2.png')
        },
        {
          title: 'mosAppCallingFriends',
          description: 'mosAppInterconnected',
          imgCard: require('@/assets/image/cards/Blog3.png')
        }
      ]
    }
  },
  computed: {
    blogDate() {
      const locale = this.$i18n.locale === 'zh' ? 'zh-cn' : 'en'
      moment.locale(locale)
      return moment(new Date('2023-05-22')).format('LL')
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.session-unlock {
    padding: 30px 0 30px;
    h1 {
      font-size: 26px;
      font-weight: 700;
      margin: 15px 0px 35px 0px;
  }
  .content-news-mosApp {
    margin-top: 10px;
    img {
      width: 100%;
      border-radius: 10px 0 0 10px;
    }
  }
  .card-list {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 10px;
    height: 600px;
    // iphon 6
    @media screen and (width: 375px) {
      height: 480px;
    }
    // iphon 6+
    @media screen and (width: 414px) {
      height: 510px;
    }
    // iphone 12 pro
    @media screen and (width: 390px) {
      height: 500px;
    }
    // iphone 14 pro
    @media screen and (width: 393px) {
      height: 500px;
    }
    // iphone 14 plus
    @media screen and (width: 428px) {
      height: 500px;
    }
    // sumsung galazy s8+
    @media screen and (width: 360px) {
      height: 470px;
    }
    // sumsung galazy A51/71
    @media screen and (width: 412px) {
      height: 510px;
    }
    // ipad mini
    @media screen and (width: 768px) {
      height: 470px;
    }
    // ipad mini
    @media screen and (width: 820px) {
      height: 470px;
    }
    // ipad pro
    @media screen and (width: 1024px) {
      height: 525px;
    }
    h1 {
      font-size: 20px;
    }
    p {
      font-size: 17px;
    }
    img {
      border-radius: 10px;
    }
    .trancats {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin: 20px 0px 40px 0px;
      color: #595959;
    }
    .time {
      font-size: 13px;
      color: #999;
    }
    .bottom {
      margin-top: 13px;
      line-height: 12px;
    }

    .button {
      float: right;
      color: #3370FF;
      background-color: #F5F5F5;
      padding: 10px 15px;
    }

    .image {
      width: 100%;
      display: block;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    .clearfix:after {
        clear: both
    }
  }
}
</style>
